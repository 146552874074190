import React from 'react'
import { Link } from 'react-router-dom'

const Smo = () => {
    return (
        <div>

            {/* <!-- Start Bread Crumb Area Home 1 --> */}
            <section className="bread-crumb-area">
                <div className="container">
                    <div className="bread-crumb-wrapper">
                        <Link to="/">Home</Link>
                        <span>
                            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.90625 7.53125L2.84375 13.625C2.53125 13.9062 2.0625 13.9062 1.78125 13.625L1.0625 12.9062C0.78125 12.625 0.78125 12.1562 1.0625 11.8438L5.875 7L1.0625 2.1875C0.78125 1.875 0.78125 1.40625 1.0625 1.125L1.78125 0.40625C2.0625 0.125 2.53125 0.125 2.84375 0.40625L8.90625 6.5C9.1875 6.78125 9.1875 7.25 8.90625 7.53125Z"
                                    fill="white" />
                            </svg>
                        </span>
                        <Link to="#" className="current-page">Service Details</Link>
                    </div>
                    <div className="bread-crumb-title">
                        <h2 className="split-collab">Social Medial Optimization</h2>
                    </div>
                </div>
            </section>
            {/* <!-- End Bread Crumb Area Home 1 --> */}

            {/* <!-- Start Blog Details Area --> */}
            <section className="blog-details-area">
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" className="blog-details-area-shep-1 blur-1" />
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" className="blog-details-area-shep-2 blur-1" />
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" className="blog-details-area-shep-3 blur-1" />
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" className="blog-details-area-shep-4 blur-1" />
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" className="blog-details-area-shep-5 blur-1" />
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <div className="blog-details-inner">
                                <div className="blog-details-banner vre-reveal-one">
                                    <img src="assets/images/blog-details/blog-details-banner-1.webp" alt="VRE"
                                        className="vre-reveal-image-one" />
                                </div>
                                <h4 className="font-size-1-35 split-collab">Maximize Your online Presence with Acuvise Technologie's Best Social Media Optimization Service in Lucknow</h4>
                                <p className="font-size-1-16">In today’s digital landscape, social media has become a crucial component of brand visibility and customer engagement. At Acuvise Technologies, we specialize in providing cutting-edge social media optimization (SMO) services designed to elevate your brand’s presence across various social platforms. As the top SMO company in Lucknow, we offer best solutions to help you connect with your audience, drive engagement, and achieve your business objectives.</p>
                                <div className="blog-details-post-wrapper">
                                    <div className="blog-details-post-1">
                                        <div className="blog-details-post-1-banner vre-reveal-one">
                                            <img src="assets/images/blog-details/blog-details-post-1.webp" alt="VRE"
                                                className="vre-reveal-image-one" />
                                        </div>
                                        <ul>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124771">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="font-size-1-18">Develop data-driven content strategies to resonate with your target audience</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124772">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="font-size-1-18">Advanced analytics-driven optimization</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124773">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="font-size-1-18">Interactions with your audience through active community management</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124774">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="font-size-1-18">Create compelling visuals and captivating copy to drive engagement</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124775">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="font-size-1-18">Engaging posts, videos, graphics, and stories tailored for each platform</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124776">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className="font-size-1-18">
                                                    Encourage users to share their experiences and stories related to your brand.</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <h5 className="font-size-1-30 split-collab">
                                        Our Approach To Social Media Optimization</h5>
                                    <p className="font-size-1-16">Ready to boost your social media presence and engage with your audience? Say hello to Acuvise Technologies’s strategic SMO services. We’ll help you shine on social platforms, reaching the right people at the right time. Sit back, relax, and watch your brand’s online presence soar with the Best Social Media Optimization Service in Lucknow!</p>
                                </div>
                                <div className="blog-details-post-2">
                                    <div className="blog-details-post-2-inner">
                                        <div className="blog-details-post-left vre-reveal-one">
                                            <img src="assets/images/blog-details/blog-details-post-2.webp" alt="VRE"
                                                className="vre-reveal-image-one" />
                                        </div>
                                        <div className="blog-details-post-right vre-reveal-one">
                                            <img src="assets/images/blog-details/blog-details-post-3.webp" alt="VRE"
                                                className="vre-reveal-image-one" />
                                        </div>
                                    </div>
                                    <h5 className="font-size-1-24 split-collab">Why Choose UP Global for Social Media Optimization?
                                    </h5>
                                    <p className="font-size-1-16">Choose Acuvise Technologies as your SMO partner and unlock the full potential of your brand on social media. Let’s elevate your online presence and connect with your audience on a deeper level!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="sitebar-wrapper">
                                <div className="sitebar-search-bar">
                                    <form action="#">
                                        <input type="text" placeholder="Search" />
                                        <button type="submit">
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M10.4193 3.646C6.68281 3.646 3.64844 6.68037 3.64844 10.4168C3.64844 14.1533 6.68281 17.1877 10.4193 17.1877C14.1557 17.1877 17.1901 14.1533 17.1901 10.4168C17.1901 6.68037 14.1557 3.646 10.4193 3.646ZM10.4193 4.68766C13.5818 4.68766 16.1484 7.25433 16.1484 10.4168C16.1484 13.5793 13.5818 16.146 10.4193 16.146C7.25677 16.146 4.6901 13.5793 4.6901 10.4168C4.6901 7.25433 7.25677 4.68766 10.4193 4.68766Z"
                                                    fill="black" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M21.2042 20.4647L15.2052 14.4668C15.0021 14.2637 14.6719 14.2637 14.4687 14.4668C14.2656 14.6699 14.2656 15.0001 14.4687 15.2033L20.4667 21.2022C20.6708 21.4053 21 21.4053 21.2042 21.2022C21.4073 20.998 21.4073 20.6689 21.2042 20.4647Z"
                                                    fill="black" />
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                                <div className="sitebar-menu">
                                    <h5 className="font-size-1-24 sitebar-title-1">Main Services</h5>
                                    <ul>
                                        <li><Link to="/digital">Digital Marketing <i className="fa-solid fa-angle-right"></i></Link></li>
                                        <li><Link to="/webdevelopment">Web Development <i className="fa-solid fa-angle-right"></i></Link></li>
                                        <li><Link to="/mobileapp">Mobile App Marketing <i className="fa-solid fa-angle-right"></i></Link></li>
                                        <li><Link to="/seo">Search Engine Optimization <i className="fa-solid fa-angle-right"></i></Link></li>
                                        <li><Link to="/smm">Social Media Optimization <i className="fa-solid fa-angle-right"></i></Link></li>
                                        <li><Link to="/smo">Social Media Marketing<i className="fa-solid fa-angle-right"></i></Link></li>
                                        <li><Link to="/graphic">Graphic Design <i className="fa-solid fa-angle-right"></i></Link></li>
                                    </ul>
                                </div>
                                <div className="sitebar-banner vre-reveal-one">
                                    <Link to="#">
                                        <img src="assets/images/sitebar/sitebar-banner.webp" alt="VRE"
                                            className="vre-reveal-image-one" />
                                    </Link>
                                </div>
                                <div className="sitebar-tag">
                                    <h5 className="font-size-1-24 sitebar-title-1">Popular Tags</h5>
                                    <div className="sitebar-tag-title">
                                        <Link to="#">Social Media</Link>
                                        <Link to="#">Digital</Link>
                                        <Link to="#">Marketing</Link>
                                        <Link to="#">Social</Link>
                                        <Link to="#">SEO Services</Link>
                                        <Link to="#">Web SEO</Link>
                                        <Link to="#">Education</Link>
                                        <Link to="#">America</Link>
                                        <Link to="#">Analysis</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Blog Details Area  --> */}

        </div>
    )
}

export default Smo
