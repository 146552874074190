import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Aboutindex from './component/About_page/aboutindex';
import Contactindex from './component/contactpage/contactindex';
import Digitalindex from './component/servicespages/digitatmarketing/digitalindex';
import WebDevelopmentindex from './component/servicespages/web_development/webdevelopmentindex';
import Seoindex from './component/servicespages/seo_page/seoindex';
import Mobileindex from './component/servicespages/mobile_app_development/mobileindex';
import Smmindex from './component/servicespages/smm_page/smmindex';

import Landingindex from './component/landing_page/Landingindex';
import Projectsindex from './component/our_work/projectsindex';
import Teamindex from './component/team/teamindex';
import Blogindex from './component/Blog/blogindex';
import Smoindex from './component/servicespages/smo/smoindex';
import Graphicindex from './component/servicespages/graphic_design/graphicindex';
import Careerindex from './component/career/careerindex';
import Termsindex from './component/terms&privacy/terms/tremsindex';

import Privacyindex from './component/terms&privacy/privacy/privacyindex';
import Ecommerceindex from './component/servicespages/Ecommerce/ecommercerindex';


function App() {
  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" element={<Landingindex />} />
        <Route path="/about" element={<Aboutindex />} />
        <Route path="/contact" element={<Contactindex />} />
        <Route path="/digital" element={<Digitalindex />} />
        <Route path="/graphic" element={<Graphicindex />} />
        <Route path="/webdevelopment" element={<WebDevelopmentindex />} />
        <Route path="/mobileapp" element={<Mobileindex/>} />
        <Route path="/seo" element={<Seoindex />} />
        <Route path="/smm" element={<Smmindex />} />
        <Route path="/smo" element={<Smoindex />} />
        <Route path="/project" element={<Projectsindex />} />
        <Route path="/team" element={<Teamindex />} />
        <Route path="/blog" element={<Blogindex />} />
        <Route path="/career" element={<Careerindex />} />
        <Route path="/terms" element={<Termsindex />} />
        <Route path="/privacy" element={<Privacyindex />} />
        <Route path="/e-commercer" element={<Ecommerceindex />} />
        

      </Routes>
    </BrowserRouter>
  );
}

export default App;
