import React from 'react'

import MobileAppDevelopment from './MobileAppDevelopment'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'


const mobileindex = () => {
  return (
    <>

      <Header />
      <MobileAppDevelopment />
      <Footer />

    </>
  )
}

export default mobileindex
