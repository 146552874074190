import React from 'react'

import Smm from './Smm'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'


const smmindex = () => {
  return (
    <>

      <Header />
      <Smm />
      <Footer />

    </>
  )
}

export default smmindex
