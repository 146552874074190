import React from 'react'

import GraphicDesign from './GraphicDesign'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'


const graphicindex = () => {
  return (
    <>

      <Header />
      <GraphicDesign />
      <Footer />

    </>
  )
}

export default graphicindex
