import React from 'react'
import { Link, } from "react-router-dom";

const Team = () => {
    return (
        <>


            <section className="bread-crumb-area">
                <div className="container">
                    <div className="bread-crumb-wrapper">
                        <Link to="/">Home</Link>
                        <span>
                            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.90625 7.53125L2.84375 13.625C2.53125 13.9062 2.0625 13.9062 1.78125 13.625L1.0625 12.9062C0.78125 12.625 0.78125 12.1562 1.0625 11.8438L5.875 7L1.0625 2.1875C0.78125 1.875 0.78125 1.40625 1.0625 1.125L1.78125 0.40625C2.0625 0.125 2.53125 0.125 2.84375 0.40625L8.90625 6.5C9.1875 6.78125 9.1875 7.25 8.90625 7.53125Z"
                                    fill="white" />
                            </svg>
                        </span>
                        <Link to="#" className="current-page">Teams</Link>
                    </div>
                    <div className="bread-crumb-title">
                        <h2 className="split-collab">Our Teams</h2>
                    </div>
                </div>
            </section>
            {/* <!-- End Bread Crumb Area Home 1 --> */}


            {/* <!-- Start Team Area  About inner--> */}
            <section class="team-area-about-inner single">
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="about-page-team-area-shep-1 blur-1" />
                <div class="container">
                    <div class="about-page-team team-area">
                        <div class="row justify-content-center">
                            <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                                <div class="team-inner">
                                    <div class="team-thumb">
                                        <img src="assets/images/team/team1.jpeg" alt="VRE" />
                                        <div class="team-social-media">
                                            <ul>
                                                <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="team-name-position">
                                        <h6 class="font-size-1-22"><a href="#">Faiz Ali</a></h6>
                                        <span class="font-size-1-16">Web Developer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                                <div class="team-inner">
                                    <div class="team-thumb">
                                        <img src="assets/images/team/team2.jpeg" alt="VRE" />
                                        <div class="team-social-media">
                                            <ul>
                                                <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                <li><a href="https://www.linkedin.com/in/faizannul-haque-ali-37569216a/"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="team-name-position">
                                        <h6 class="font-size-1-22"><a href="#">Sneha Verma</a></h6>
                                        <span class="font-size-1-16">Social Media Manager</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                                <div class="team-inner">
                                    <div class="team-thumb">
                                        <img src="assets/images/team/team4.jpeg" alt="VRE" />
                                        <div class="team-social-media">
                                            <ul>
                                                <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="team-name-position">
                                        <h6 class="font-size-1-22"><a href="#">Shaziya Khan</a></h6>
                                        <span class="font-size-1-16">Graphic Desiner</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                                <div class="team-inner">
                                    <div class="team-thumb">
                                        <img src="assets/images/team/team3.jpeg" alt="VRE" />
                                        <div class="team-social-media">
                                            <ul>
                                                <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="team-name-position">
                                        <h6 class="font-size-1-22"><a href="#">Anshika</a></h6>
                                        <span class="font-size-1-16">Graphic Desiner</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                                <div class="team-inner">
                                    <div class="team-thumb">
                                        <img src="assets/images/team/team-5.webp" alt="VRE" />
                                        <div class="team-social-media">
                                            <ul>
                                                <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                                <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="team-name-position">
                                        <h6 class="font-size-1-22"><a href="#">Shiv Pandey</a></h6>
                                        <span class="font-size-1-16">Sales Executive</span>
                                    </div>
                                </div>
                            </div>


                            {/* <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-6.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="#">Dran Sami</a></h6>
                                <span class="font-size-1-16">Marketing Eng</span>
                            </div>
                        </div>
                    </div> */}


                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Team Area About inner --> */}
        </>
    )
}

export default Team
