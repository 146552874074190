import React from 'react'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'

const ecommercerindex = () => {
  return (
    <>
    <Header/>
    <Footer/>
    </>
  )
}

export default ecommercerindex
