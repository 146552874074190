import React from 'react'

import WebDevelopment from './WebDevelopment'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'


const webdevelopmentindex = () => {
  return (
    <>

      <Header />
      <WebDevelopment />
      <Footer />

    </>
  )
}

export default webdevelopmentindex
