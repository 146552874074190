import React from 'react'
import Team from './Team'
import Header from '../header/Header'
import Footer from '../footer/Footer'


const teamindex = () => {
  return (
    <>
      <Header/>
      <Team/>
      <Footer/>
    </>
  )
}

export default teamindex
