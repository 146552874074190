import React from 'react'

import Smo from './Smo'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'


const smoindex = () => {
  return (
    <>

      <Header />
      <Smo />
      <Footer />

    </>
  )
}

export default smoindex
