import React from 'react'
import { Link } from 'react-router-dom'

const Projects = () => {
  return (
    <>
      
      <section className="bread-crumb-area">
        <div className="container">
            <div className="bread-crumb-wrapper">
                <Link to="index.html">Home</Link>
                <span>
                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.90625 7.53125L2.84375 13.625C2.53125 13.9062 2.0625 13.9062 1.78125 13.625L1.0625 12.9062C0.78125 12.625 0.78125 12.1562 1.0625 11.8438L5.875 7L1.0625 2.1875C0.78125 1.875 0.78125 1.40625 1.0625 1.125L1.78125 0.40625C2.0625 0.125 2.53125 0.125 2.84375 0.40625L8.90625 6.5C9.1875 6.78125 9.1875 7.25 8.90625 7.53125Z"
                            fill="white" />
                    </svg>
                </span>
                <Link to="#" className="current-page">Our Works</Link>
            </div>
            <div className="bread-crumb-title">
                <h2 className="split-collab">Our Works</h2>
            </div>
        </div>
    </section>
    {/* <!-- End Bread Crumb Area Home 1 --> */}

   

    {/* <!-- Start Offer Area Home 3 --> */}
    <section className="offer-area-home-3 project-grid-area project-p">
        <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" className="project-grid-area-1-shep-1 blur-1"/>
        <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" className="project-grid-area-1-shep-2 blur-1"/>
        <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" className="project-grid-area-1-shep-3 blur-1"/>
        <div className="container">
            <div className="title">
                <div className="sub-title sub-title-home-2">
                    <p>WHAT WE OFFER</p>
                </div>
                <div className="main-title">
                    <h3 className="split-collab">We Offer A Wide Range Of <br/>
                        Digital Marketing <span><img src="assets/images/shep/text-shep-1.png" alt="VRE"/></span></h3>
                </div>
            </div>
            <div className="project-grid-btn mt-80">
                <ul>
                    <li data-filter="*">Show All</li>
                    <li data-filter=".cat-1">Business</li>
                    <li data-filter=".cat-2">Cyber Security</li>
                    <li data-filter=".cat-3">Development</li>
                    <li data-filter=".cat-4">Branding</li>

                </ul>
            </div>
            <div className="offer-wrapper">
                <div className="row justify-content-center project-grid-active">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="offer-inner three four">
                            <img src="assets/images/project-grid/project-1.webp" alt="VRE"/>
                            <div className="offer-name">
                                <h5 className="font-size-1-24"><Link to="project-details.html">Quality Work</Link></h5>
                                <span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 cat-2 cat-3">
                        <div className="offer-inner">
                            <img src="assets/images/project-grid/project-2.webp" alt="VRE"/>
                            <div className="offer-name">
                                <h5 className="font-size-1-24"><Link to="project-details.html">Marketing Agency</Link></h5>
                                <span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 cat-1 cat4 cat-2">
                        <div className="offer-inner">
                            <img src="assets/images/project-grid/project-3.webp" alt="VRE"/>
                            <div className="offer-name">
                                <h5 className="font-size-1-24"><Link to="project-details.html">Professional Team</Link></h5>
                                <span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 cat-4 cat-2">
                        <div className="offer-inner">
                            <img src="assets/images/project-grid/project-1.webp" alt="VRE"/>
                            <div className="offer-name">
                                <h5 className="font-size-1-24"><Link to="project-details.html">Speed Optimization</Link></h5>
                                <span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 cat-3">
                        <div className="offer-inner">
                            <img src="assets/images/project-grid/project-2.webp" alt="VRE"/>
                            <div className="offer-name">
                                <h5 className="font-size-1-24"><Link to="project-details.html">Creative Agency</Link></h5>
                                <span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 cat-1 cat-2 cat-4">
                        <div className="offer-inner">
                            <img src="assets/images/project-grid/project-1.webp" alt="VRE"/>
                            <div className="offer-name">
                                <h5 className="font-size-1-24"><Link to="project-details.html">SEO Marketter</Link></h5>
                                <span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- End Offer Area Home 3 -->  */}

    </>
  )
}

export default Projects
