import React from 'react'

import Seo from './Seo'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'


const seoindex = () => {
  return (
    <>

      <Header />
      <Seo />
      <Footer />

    </>
  )
}

export default seoindex
